<template>
  <g ref="axis" :class="[classList]" :style="style" />
</template>

<script>
import * as d3 from 'd3';

export default {
  name: 'Axis',
  props: {
    axis: {
      type: String,
      default: '',
    },
    layout: {
      type: Object,
      default: () => {},
    },
    scale: {
      type: Object,
      default: () => {},
    },
    activeScale: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      classList: ['axis'].concat(this.getAxisClasses()),
    };
  },
  computed: {
    style() {
      return {
        transform: this.getAxisTransform(),
      };
    },
  },
  watch: {
    // Changes to scale means we have to redraw the line!
    scale: {
      deep: true,
      handler: 'drawAxis',
    },
    activeScale: {
      deep: true,
      handler: 'drawAxis',
    },
  },
  mounted() {
    this.drawAxis();
  },
  methods: {

    // Return a class list containg the appropriate labels for axes
    getAxisClasses() {
      const axis = {
        bottom: 'x',
        left: 'y',
      };
      return [this.axis, axis[this.axis]];
    },

    // Draw axis
    drawAxis() {
      const $axis = d3.select(this.$refs.axis);
      const { scale } = this;

      let axisTicks = 3;
      if (this.activeScale) {
        axisTicks = Object.keys(this.activeScale).length;
      }

      const axisGenerator = {
        bottom: d3
          .axisBottom(scale.x),
        left: d3
          .axisLeft(scale.y)
          .ticks(axisTicks)
          .tickFormat((value) => {
            if (this.activeScale != null) {
              return this.activeScale[value];
            }

            if (value === 100) {
              return this.$i18n.t('skill.graph.level.professional');
            }
            if (value === 50) {
              return this.$i18n.t('skill.graph.level.experienced');
            }
            if (value === 0) {
              return this.$i18n.t('skill.graph.level.amateur');
            }

            return '';
          }),
      };

      $axis.call(axisGenerator[this.axis]);
    },

    // Return necessary axis transformation for proper positioning
    getAxisTransform() {
      const axisOffset = {
        bottom: { x: 0, y: this.layout.height },
        left: { x: 0, y: 0 },
      };

      return `translate(${axisOffset[this.axis].x}px, ${axisOffset[this.axis].y}px)`;
    },
  },
};
</script>


<style lang="less">
  .tick {
    font-family: "Raleway", "HelveticaNeue", "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-size: 2.8em;

    @media (min-width: 550px) {
      font-size: 2.4em;
    }
    @media (min-width: 750px) {
      font-size: 1.8em;
    }
    @media (min-width: 1000px) {
      font-size: 1.5em;
    }
  }
</style>
