import api from '../../api/skill';

export default {
  namespaced: true,

  state: () => ({
    activeSkill: [],
    activeScale: null,
    error: '',
    loading: false,
  }),

  mutations: {
    toggleLoading(state) {
      state.loading = !state.loading;
    },
    setSkill(state, skill) {
      state.activeSkill = skill;
    },
    setScale(state, scale) {
      state.activeScale = scale;
    },
    setError(state, message) {
      state.error = message;
    },
  },

  actions: {
    loadSkill({ commit }, id) {
      commit('setError', '');
      commit('toggleLoading');

      api.getSkill(id).then(
        (response) => {
          commit('toggleLoading');

          if ('exists' in response.data && response.data.exists === false) {
            commit('setError', 'Code does not exists');
          } else {
            commit('setSkill', response.data.data);

            if (response.scale) {
              commit('setScale', response.data.scale);
            } else {
              commit('setScale', response.data.scale);
            }
          }
        },
        () => {
          commit('toggleLoading');
          commit('setError', 'Something went wrong');
        },
      );
    },
    clear({ commit }) {
      commit('setError', '');
      commit('setSkill', []);
    },
  },

};
