<template>
  <section id="welcome" class="row">
    <div id="lang" class="twelve columns">
      <a href="#" :class="{'activeLang': $i18n.locale === 'de'}" @click="$i18n.locale = 'de'">de</a>
      |
      <a href="#" :class="{'activeLang': $i18n.locale === 'en'}" @click="$i18n.locale = 'en'">en</a>
    </div>
    <div id="avatar" class="twelve columns">
      <img src="../assets/images/avatar.svg" class="four columns" alt="avatar">
    </div>
    <div id="greetings" class="twelve columns">
      <div id="whoami" class="four columns">
        {{ $t('welcome.hello') }}
        <h1 id="name">
          Ramon
        </h1>
      </div>
      <transition name="fade">
        <div v-if="jobtitle && company" id="connector" class="two columns">
          &amp;
        </div>
      </transition>
      <transition name="fade">
        <div v-if="jobtitle && company" id="whoyouare" class="six columns">
          {{ $t('welcome.applyas') }}
          <span id="title">{{ jobtitle[$i18n.locale] }}</span>
          {{ $t('welcome.applyto') }}
          <span id="company">{{ company[$i18n.locale] }}</span>
        </div>
      </transition>
    </div>
  </section>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'Welcome',
  computed: {
    ...mapState({
      jobtitle: (state) => state.company.jobtitle,
      company: (state) => state.company.name,
    }),
  },
  watch: {
    locale(val) {
      this.$i18n.locale = val;
    },
  },
};
</script>

<style lang="less">
@import "../assets/less/variables.less";

#welcome {
  font-size: 18px;
  background-color: @panel-welcome--bg-color;
  box-shadow: 6px 12px 9px 0px rgba(0, 0, 0, 0.2);

  #lang {
    padding-top: 10px;
    text-align: right;

    .activeLang {
      text-decoration: none;
      cursor: default;
    }
  }

  #greetings{
    padding-top: 10px;
    padding-bottom: 30px;

    #whoyouare{
      @media screen {
        text-align: right;

        @media (max-width: 549px) {
          text-align: left;
        }
      }

      #title, #company {
        font-weight: bold;
      }
    }

    #whoami {
      #name {
        font-size: 36px;
        margin-top: 0;
        margin-bottom: 0;
        font-weight: 400;
      }
    }
    #connector {
      @media screen {
        font-size: 70px;
        line-height: 70px;
        color: @color-chrome-yellow;

        @media (max-width: 549px) {
          margin-top: 3px;
          margin-bottom: 3px;
        }
      }

    }
  }
}
</style>
