<template>
  <div class="event">
    <div :class="['event_img', event.image]" />
    <div class="event_content">
      <div v-if="event.title[$i18n.locale]" class="title">
        {{ event.title[$i18n.locale] }}
      </div>
      <div class="description" v-html="event.text[$i18n.locale]" />
      <div v-if="event.attachments">
        <TimelineAttachment
          v-for="(attachment, index) in event.attachments"
          :key="index"
          :attachment="attachment"
        />
      </div>
      <div class="year">
        {{ date }}
      </div>
    </div>
  </div>
</template>

<script>
import TimelineAttachment from './TimelineAttachment.vue';

export default {
  name: 'TimelineEntry',
  components: {
    TimelineAttachment,
  },
  props: {
    event: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    date() {
      const from = new Date(1970, 0, 1);
      const to = new Date(1970, 0, 1);

      if (this.event.date) {
        from.setSeconds(this.event.date._seconds);

        return this.$d(from, 'date');
      }

      if (this.event.monthFrom && this.event.monthTo) {
        from.setSeconds(this.event.monthFrom._seconds);
        to.setSeconds(this.event.monthTo._seconds);

        return `${this.$d(from, 'yearMonth')} - ${this.$d(to, 'yearMonth')}`;
      }

      if (this.event.yearFrom && this.event.yearTo) {
        from.setSeconds(this.event.yearFrom._seconds);
        to.setSeconds(this.event.yearTo._seconds);

        return `${this.$d(from, 'yearOnly')} - ${this.$d(to, 'yearOnly')}`;
      }

      if (this.event.duration) {
        return this.event.duration[this.$i18n.locale];
      }

      return '';
    },
  },
};
</script>

<style lang="less">
@import "../assets/less/variables.less";

.event{
    position: relative;
    clear: both;

    .event_img {
      position: absolute;
      top: 0;
      left: 4px;
      width: 40px;
      height: 40px;
      border-radius: 50%;
      box-shadow:
        0 0 0 4px white,
        inset 0 2px 0 rgba(0, 0, 0, 0.08),
        0 3px 0 4px rgba(0, 0, 0, 0.05);
      background-color: @panel-welcome--bg-color;
      background-size: 24px;
      background-repeat: no-repeat;
      background-position: 8px 8px;
    }

    .event_img.birthday {
      background-image: url(../assets/images/material_birthday.svg);
    }
    .event_img.education {
      background-image: url(../assets/images/material_education.svg);
    }
    .event_img.work {
      background-image: url(../assets/images/material_work.svg);
    }
    .event_img.travel {
      background-image: url(../assets/images/material_travel.svg);
    }

    .event_content::before {
      content: '';
      position: absolute;
      top: 15px;
      right: 100%;
      height: 0;
      width: 0;
      border: 7px solid transparent;
      border-right: 7px solid @panel-welcome--bg-color;
    }
    .event_content {
      background-color: @panel-welcome--bg-color;
      color: @font-color;

      position: relative;
      margin: 10px 0 10px 60px;
      padding: 10px 10px 10px 10px;

      a {
        color: @font-link-color;

        &:hover{
          color: @font-link-color;
        }
      }

      .title {
        @media screen {
          font-size: 18px;

          @media (max-width: 549px) {
            font-size: 16px;
          }
        }

        font-weight: bold;
        padding-bottom: 2px;
      }
      .year {
        padding-top: 5px;
        color: @font-color--grey;
      }

      .description {
        ul {
          margin-top: 15px;
          margin-bottom: 15px;
          margin-left: 30px;

          li {
            list-style-position: initial;
            margin-bottom: 0;
          }
        }
      }
    }
}
</style>
