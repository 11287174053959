import Vue from 'vue';
import Vuex from 'vuex';
import company from './modules/company';
import timeline from './modules/timeline';
import skill from './modules/skill';

Vue.use(Vuex);

const debug = process.env.NODE_ENV !== 'production';

export default new Vuex.Store({
  modules: {
    company,
    timeline,
    skill,
  },
  strict: debug,
  plugins: debug ? [] : [],
});
