<template>
  <section id="connect" class="row">
    <div id="dart_hobbies" class="dart" />

    <div class="intro twelve columns">
      <h2>{{ $t('connect.title') }}</h2>
      <p>{{ $t('connect.description') }}</p>
    </div>

    <div id="contact_options" class="twelve columns">
      <div id="email" class="contact four columns">
        <img src="../assets/images/contact_mail.svg" alt="EMail">
        <a href="mailto:r@solved-it.ch">r@solved-it.ch</a>
      </div>
      <div id="email" class="contact four columns">
        <img src="../assets/images/contact_phone.svg" alt="Phone">
        <a href="tel://0041795835324">+41 79 583 53 24</a>
      </div>
      <div id="email" class="contact four columns">
        <img src="../assets/images/contact_github.svg" alt="GH">
        <a href="https://github.com/binzram" target="_blank">GitHub</a>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'Connect',
};
</script>

<style lang="less">
@import "../assets/less/variables.less";

#connect {
  background-color: @panel-connect--bg-color;

  .dart {
    border-top: 40px solid @panel-hobbies--bg-color;
  }

  .contact {
    @media screen {
      text-align: center;
      padding-bottom: 40px;

      @media (max-width: 549px) {
        text-align: left;
        padding-bottom: 20px;
      }
    }
  }

  .contact img {
    top: 6px;
    width: 24px;
    padding-right: 3px;
    position: relative;
  }

  .references {
    p {
      font-size: 18px;

      @media screen {
        @media (max-width: 549px) {
          margin-top: 2.2em;
        }
      }
    }
  }

  #references_entries {
    margin-bottom: 30px;

    .reference {
      p {
        margin-bottom: 0;
      }

      .title {
        font-weight: bold;
      }

      @media screen {
        @media (max-width: 549px) {
          padding-bottom: 20px;
        }
      }
    }
  }
}
</style>
