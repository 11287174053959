import api from '../../api/timeline';

export default {
  namespaced: true,

  state: () => ({
    events: [{
      date: {
        _seconds: 0,
      },
      image: 'loading',
      text: { de: 'Einen Moment geduld, die Einträge werden geladen', en: 'Please wait, the entries are loading' },
      title: { de: 'Lädt...', en: 'Loading...' },
    }],
    error: '',
    loading: false,
  }),

  mutations: {
    toggleLoading(state) {
      state.loading = !state.loading;
    },
    setEvents(state, events) {
      state.events = events;
    },
    setError(state, message) {
      state.error = message;
    },
  },

  actions: {
    loadEvents({ commit }, elements) {
      commit('setError', '');
      commit('toggleLoading');

      api.getTimeline(elements).then(
        (response) => {
          commit('toggleLoading');
          commit('setEvents', response.data);
        },
        () => {
          commit('toggleLoading');
          commit('setError', 'Something went wrong');
        },
      );
    },
  },

};
