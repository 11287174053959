<template>
  <g class="d3__series">
    <Area
      :layout="layout"
      :series-data="seriesData"
      :scale="scale"
    />
    <LineD
      :layout="layout"
      :series-data="seriesData"
      :scale="scale"
    />
  </g>
</template>

<script>
import Area from './Area.vue';
import LineD from './LineD.vue';

export default {
  name: 'Series',
  components: {
    Area,
    LineD,
  },
  props: {
    layout: {
      type: Object,
      default: () => {},
    },
    seriesData: {
      type: Object,
      default: () => {},
    },
    scale: {
      type: Object,
      default: () => {},
    },
  },
};
</script>
