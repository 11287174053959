<template>
  <button @click="downloadFile()">
    {{ label }}
  </button>
</template>

<script>
import axios from 'axios';
import firebase from 'firebase/app';
import 'firebase/storage';

export default {
  name: 'TimelineAttachment',
  props: {
    attachment: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      loading: false,
      error: '',
    };
  },
  computed: {
    label() {
      if (this.loading) {
        return this.$t('timeline.attachments.loading');
      }
      if (this.error) {
        return this.error;
      }

      return this.attachment.label[this.$i18n.locale];
    },
  },
  methods: {
    downloadFile() {
      this.loading = true;
      const storage = firebase.storage();
      const gsReference = storage.refFromURL(this.attachment.location);

      gsReference.getDownloadURL().then((url) => {
        axios({
          method: 'get',
          url,
          responseType: 'blob',
        })
          .then((response) => {
            const link = document.createElement('a');
            link.href = window.URL.createObjectURL(new Blob([response.data]));
            link.setAttribute('download', `${this.attachment.label[this.$i18n.locale]}.pdf`);
            document.body.appendChild(link);
            link.click();

            this.loading = false;
          });
      }).catch((error) => {
        this.error = error;
      });
    },
  },
};
</script>

<style lang="less">
@import "../assets/less/variables.less";

button {
  background-color: @color-columbia-blue !important;
  border-color: @color-columbia-blue !important;

  color: @font-color--dark !important;
  margin: 10px 10px 0 0;
}
</style>
