<template>
  <section id="skills" class="row">
    <div id="dart_timeline" class="dart" />

    <div class="intro twelve columns">
      <h2>{{ $t('skill.title') }}</h2>
      <p>{{ $t('skill.description') }}</p>
    </div>

    <div class="categories twelve columns">
      <div class="five columns">
        <label for="category">{{ $t('skill.categories.title') }}</label>
        <select id="category" v-model="category" class="u-full-width">
          <optgroup v-for="(optgroup, index) in categories" :key="index" :label="$t(index)">
            <option v-for="cat in optgroup" :key="cat.value" :value="cat.value">
              {{ $t('skill.categories.skill.' + cat.value) }}
            </option>
          </optgroup>
        </select>
      </div>

      <div class="six columns offset-by-one">
        <ul class="legend-series">
          <li
            v-for="entry in legendes"
            :key="entry.id"
            :class="['legend-series-'+(entry.id+1), activeLegendeClass(entry.id)]"
            @click="legendSelected(entry.id)"
          >
            {{ entry.label }}
          </li>
        </ul>
      </div>
    </div>

    <Chart
      ref="chart"
      :chart-data="datacollection"
      :layout="layout"
      :axes="axes"
      :active-scale="activeScale"
    />
  </section>
</template>

<script>
import { mapState } from 'vuex';
import Chart from './Chart/Chart.vue';

export default {
  name: 'Skill',
  components: {
    Chart,
  },
  data() {
    return {
      category: 'app.elixir',
      categories: {
        'skill.categories.grouping.appdevelopment': [
          { value: 'app.elixir' },
          { value: 'app.java' },
          { value: 'app.javascript' },
          { value: 'app.php' },
          { value: 'app.ruby' },
          { value: 'app.webdesign' },
          { value: 'app.others' },
        ],
        'skill.categories.grouping.systemengineering': [
          { value: 'sys.os' },
          { value: 'sys.devops' },
          { value: 'sys.cloud' },
          { value: 'sys.database' },
          { value: 'sys.scripting' },
          { value: 'sys.virt' },
        ],
        'skill.categories.grouping.softskills': [
          { value: 'skills.language' },
        ],
      },
      datacollection: [],
      axes: ['left', 'bottom'],
      legendes: [],
      activeLegendes: [],
      layout: {
        width: 800,
        height: 250,
        marginTop: 45,
        marginRight: 35,
        marginBottom: 50,
        marginLeft: 125,
      }
    };
  },
  computed: {
    ...mapState({
      loading: (state) => state.skill.loading,
      error: (state) => state.skill.error,
      activeSkill: (state) => state.skill.activeSkill,
      activeScale: (state) => state.skill.activeScale,
    }),
    locale() {
      return this.$i18n.locale;
    },
  },
  watch: {
    activeSkill() {
      this.setLegendeData();
    },
    activeLegendes() {
      this.setGraphData();
      this.calcGraphWidth();
    },
    category(value) {
      this.$store.dispatch('skill/clear');
      this.$store.dispatch('skill/loadSkill', value);
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.calcGraphWidth();
    })
  },
  created() {
    this.$store.dispatch('skill/loadSkill', this.category);
    window.addEventListener("resize", this.calcGraphWidth);
  },
  destroyed() {
    window.removeEventListener("resize", this.myEventHandler);
  },
  methods: {
    calcGraphWidth(e) {
      let graphWidth = this.$refs.chart.$el.clientWidth;
      let newWidth = 180;

      if(graphWidth > 750) {
          newWidth = 125;
      }
      if(graphWidth > 550) {
          newWidth = 140;
      }

      if(this.category == 'skills.language') {
          newWidth = 40;
      }

      this.layout.marginLeft = newWidth;
    },
    setGraphData() {
      const datacollection = [];
      // const xaxis = ['2007', '2011', '2015', '2016', '2017', '2018', '2019', '2020'];
      const xaxis = ['2007', '2011', '2017', '2018', '2019', '2020', '2021', '2022'];

      for (const [index, skill] of this.activeSkill.entries()) {
        if (this.activeLegendes.includes(index)) {
          const data = skill.data.map((value, i) => ({
            timestamp: xaxis[i],
            value,
          }));

          datacollection.push({
            id: index,
            values: data,
          });
        }
      }

      this.datacollection = datacollection;
    },
    setLegendeData() {
      const legendes = [];
      const activeLegendes = [];

      for (const [index, skill] of this.activeSkill.entries()) {
        legendes.push({
          id: index,
          label: skill.title.de,
        });
        activeLegendes.push(index);
      }

      this.legendes = legendes;
      this.activeLegendes = activeLegendes;
    },
    activeLegendeClass(id) {
      for (const legende of this.activeLegendes) {
        if (legende === id) {
          return 'active';
        }
      }

      return '';
    },
    legendSelected(id) {
      if (this.activeLegendes.length === 1 && this.activeLegendes[0] === id) {
        const activeLegendes = [];
        for (const legende of this.legendes) {
          activeLegendes.push(legende.id);
        }
        this.activeLegendes = activeLegendes;
      } else {
        for (const legende of this.legendes) {
          if (legende.id === id) {
            this.activeLegendes = [legende.id];
          }
        }
      }
    },
  },
};
</script>

<style lang="less">
@import "../assets/less/variables.less";

#skills {
  background-color: @panel-skills--bg-color;

  .dart {
    border-top: 40px solid @panel-timeline--bg-color;
  }

  .categories {
    margin-bottom: .5em;

    ul {
      margin-top: 35px;
      position: relative;
      z-index: 10;
    }

    ul li {
      &::before {
        content: '';
        position: absolute;
        width: 22px;
        height: 22px;
        left: -34px;
      }

      position: relative;
      float: left;
      margin-right: 25px;
      margin-left: 34px;
      list-style: none;
      user-select: none;
      cursor: pointer;
    }
  }

  .legende-color(@list, @i: 1) when (@i <= length(@list)) {
    // extract the right color from the list
    @color: extract(@list, @i);

    // apply the background to the selector
    .legend-series-@{i} {
      &::before {
        border: 1px @color solid;
      }

      &.active {
        &::before {
          background: @color;
        }
      }
    }

    // recursive call for the next color
    .legende-color(@list, @i + 1);
  }

  @colors: @color-chrome-yellow, @color-usc-gold, @color-graph, @color-columbia-blue;
  .legende-color(@colors);

  #timeline_stats {
    margin-bottom: 30px;
  }
}
</style>
