<template>
  <div class="container">
    <section class="six columns offset-by-three row row-color--dark code">
      <h2 class="title">
        Enter your code
      </h2>
      <img src="../assets/images/avatar.svg" class="twelve columns avatar" alt="avatar">
      <div class="twelve columns form">
        <input
          v-model="enteredCode"
          class="u-full-width"
          type="text"
          placeholder="Code"
          @keyup.enter="requestAccess"
        >
        <p v-if="loading">
          Loading
        </p>
        <p v-if="error" v-text="error" />
        <button class="u-full-width button-primary" @click="requestAccess">
          Access
        </button>
      </div>
    </section>
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'Access',
  props: {
    code: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      enteredCode: null,
    };
  },
  computed: {
    ...mapState({
      loading: (state) => state.company.loading,
      error: (state) => state.company.error,
      company: (state) => state.company.id,
    }),
  },
  watch: {
    company() {
      this.$router.push({
        name: 'cv',
        params: {
          code: this.enteredCode,
        },
      });
    },
  },
  methods: {
    requestAccess() {
      this.$store.dispatch('company/loadCompany', this.enteredCode);
    },
  },
};
</script>

<style lang="less">
@import "../assets/less/variables.less";
@import "../assets/less/base.less";

.row--shadow-less {
    box-shadow: none !important;
}

.code {
    background-color: @color-quarz;
    color: @font-color;

    margin: 40px 0 0 0;
    padding: 20px 0 20px 0;
    box-shadow: 6px 12px 9px 0px rgba(0, 0, 0, 0.2);

    .title {
      color: @font-color;
      text-align: center;
    }

    .avatar {
      height: 150px;
      margin-bottom: 20px;
    }

    .form {
      color: @font-color;

      .button-primary {
        background-color: @color-columbia-blue !important;
        border-color: @color-columbia-blue !important;

        color: @font-color--dark !important;
      }
    }
}
</style>
