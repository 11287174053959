import axios from './client';

export default {
  getTimeline(events) {
    const url = new URL(`${axios.defaults.baseURL}/getTimeline`);

    for (const event of events) {
      url.searchParams.append('elements', event);
    }

    return axios.get(url.toString());
  },
};
