import Vue from 'vue';
import VueRouter from 'vue-router';
import Access from '../views/Access.vue';
import Vita from '../views/Vita.vue';

Vue.use(VueRouter);

const routes = [
  {
    path: '/vita/:code',
    name: 'cv',
    component: Vita,
    props: true,
  },
  {
    path: '*',
    name: 'access',
    component: Access,
    props: true,
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

export default router;
