<template>
  <div class="container">
    <Welcome />
    <Timeline />
    <Skill />
    <Hobbies />
    <Connect />
  </div>
</template>

<script>
import { mapState } from 'vuex';
import firebase from 'firebase/app';
import 'firebase/auth';
import Welcome from '@/components/Welcome.vue';
import Timeline from '@/components/Timeline.vue';
import Skill from '@/components/Skill.vue';
import Hobbies from '@/components/Hobbies.vue';
import Connect from '@/components/Connect.vue';

export default {
  name: 'Cv',
  components: {
    Welcome,
    Timeline,
    Skill,
    Hobbies,
    Connect,
  },
  props: {
    code: {
      type: String,
      default: '',
    },
  },
  computed: {
    ...mapState({
      loading: (state) => state.company.loading,
      error: (state) => state.company.error,
      company: (state) => state.company.id,
    }),
  },
  watch: {
    error() {
      this.$router.push({
        name: 'access',
        params: {
          code: this.code,
        },
      });
    },
    company() {
      this.anonLogin();
    },
  },
  created() {
    if (this.company == null) {
      this.$store.dispatch('company/loadCompany', this.code);
    } else {
      this.anonLogin();
    }
  },
  methods: {
    anonLogin() {
      firebase.auth().signInAnonymously().catch((error) => {
        // Handle Errors here.
        const errorCode = error.code;
        const errorMessage = error.message;
        console.error(errorCode, errorMessage);
      });
    },
  },
};
</script>
