import api from '../../api/company';

export default {
  namespaced: true,

  state: () => ({
    id: null,
    jobtitle: null,
    name: null,
    motivation: null,
    timelineEvents: [],
    error: '',
    loading: false,
  }),

  mutations: {
    toggleLoading(state) {
      state.loading = !state.loading;
    },
    setCompany(state, company) {
      state.id = company.id;
      state.jobtitle = company.jobtitle;
      state.name = company.name;
      state.motivation = company.motivation;
      state.timelineEvents = company.timelineElements;
    },
    setError(state, message) {
      state.error = message;
    },
  },

  actions: {
    loadCompany({ commit }, code) {
      commit('setError', '');
      commit('toggleLoading');

      api.getCompany(code).then(
        (response) => {
          commit('toggleLoading');

          if ('exists' in response.data && response.data.exists === false) {
            commit('setError', 'Code does not exists');
          } else {
            commit('setCompany', response.data);
          }
        },
        () => {
          commit('toggleLoading');
          commit('setError', 'Something went wrong');
        },
      );
    },
  },

};
