<template>
  <svg :view-box.camel="viewBox" preserveAspectRatio="xMidYMid meet">
    <g class="d3__stage" :style="stageStyle">
      <Axis
        v-for="(axis, index) in axes"
        :key="`axis-${index}`"
        :axis="axis"
        :layout="layout"
        :scale="scale"
        :active-scale="activeScale"
      />
      <Series
        v-for="(seriesData, index) in chartData"
        :key="`series-${index}`"
        :seriesData="seriesData"
        :layout="layout"
        :scale="scale"
      />
    </g>
  </svg>
</template>

<script>
import * as d3 from 'd3';
import Axis from './Axis.vue';
import Series from './Series.vue';

export default {
  name: 'Chart',
  components: {
    Axis,
    Series,
  },
  props: {
    axes: {
      type: Array,
      default: () => {},
    },
    layout: {
      type: Object,
      default: () => {},
    },
    chartData: {
      type: Array,
      default: () => {},
    },
    activeScale: {
      type: Object,
      default: () => null,
    },
  },
  data() {
    return {
      scale: {
        x: this.getScaleX(),
        y: this.getScaleY(),
        color: d3.scaleOrdinal()
          // @color-chrome-yellow, @color-usc-gold, @color-graph, @color-columbia-blue
          .range(['#FF8F00', '#FFCD00', '#5FDE23', '#B2DFDB']),
      },
    };
  },
  computed: {

    // SVG viewbox
    viewBox() {
      const outerWidth = this.layout.width + this.layout.marginLeft + this.layout.marginRight;
      const outerHeight = this.layout.height + this.layout.marginTop + this.layout.marginBottom;
      return `0 0 ${outerWidth} ${outerHeight}`;
    },

    // Stage
    stageStyle() {
      return {
        transform: `translate(${this.layout.marginLeft}px,${this.layout.marginTop}px)`,
      };
    },
  },
  watch: {
    // Watch for layout changes
    layout: {
      deep: true,
      handler() {
        this.scale.x = this.getScaleX();
        this.scale.y = this.getScaleY();
      },
    },
  },
  methods: {

    // Get x-axis scale
    getScaleX() {
      return d3.scalePoint()
        .range([0, this.layout.width])
        .domain(['2007', '2011', '2017', '2018', '2019', '2020', '2021', '2022']);
    },

    // Get y-axis scale
    getScaleY() {
      return d3.scaleLinear()
        .range([this.layout.height, 0])
        .domain([0, 100]);
    },
  },
};
</script>


<style lang="less">
  svg {
    font-familiy: "Raleway", "HelveticaNeue", "Helvetica Neue", Helvetica, Arial, sans-serif;
  }
</style>
