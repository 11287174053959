<template>
  <section id="timeline" class="row row-color--dark">
    <div id="dart_welcome" class="dart" />

    <div class="intro twelve columns">
      <h2>{{ $t('timeline.title') }}</h2>
      <p>{{ $t('timeline.description') }}</p>
    </div>

    <div id="timeline_events" class="twelve columns">
      <TimelineEntry v-for="(event, index) in events" :key="index" :event="event" />
    </div>
  </section>
</template>

<script>
import { mapState } from 'vuex';
import TimelineEntry from './TimelineEntry.vue';

export default {
  name: 'Timeline',
  components: {
    TimelineEntry,
  },
  data() {
    return {
      companyCode: this.$parent.$props.companycode,
    };
  },
  computed: {
    ...mapState({
      loading: (state) => state.timeline.loading,
      error: (state) => state.timeline.error,
      events: (state) => state.timeline.events,
      motivation: (state) => state.company.motivation,
      timelineEvents: (state) => state.company.timelineEvents,
    }),
  },
  watch: {
    timelineEvents(val) {
      this.loadTimeline(val);
    },
  },
  created() {
    if (this.timelineEvents.length > 0) {
      this.loadTimeline(this.timelineEvents);
    }
  },
  methods: {
    loadTimeline(events) {
      this.$store.dispatch('timeline/loadEvents', events);
    },
  },
};
</script>

<style lang="less">
@import "../assets/less/variables.less";

#timeline {
  background-color: @panel-timeline--bg-color;

  .dart {
    border-top: 40px solid @panel-welcome--bg-color;
  }

  #timeline_events {
    position: relative;
    margin-bottom: 30px;

    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 22px;
      height: 100%;
      width: 4px;
      background: @panel-welcome--bg-color;
    }
  }
}
</style>
