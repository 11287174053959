<template>
  <section id="hobbies" class="row row-color--dark">
    <div id="dart_skills" class="dart" />

    <div class="intro twelve columns">
      <h2>{{ $t('hobbies.title') }}</h2>
      <p>{{ $t('hobbies.description') }}</p>
    </div>

    <div id="hobby_list" class="twelve columns">
      <div id="sports" class="hobby four columns">
        <img src="../assets/images/hobby_sport.svg" alt="Sports">
        {{ $t('hobbies.list.sport') }}
      </div>
      <div id="explore" class="hobby four columns">
        <img src="../assets/images/hobby_explore.svg" alt="Explore">
        {{ $t('hobbies.list.explore') }}
      </div>
      <div id="cooking" class="hobby four columns">
        <img src="../assets/images/hobby_cooking.svg" alt="Cooking">
        {{ $t('hobbies.list.cooking') }}
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'Hobbies',
};
</script>

<style lang="less">
@import "../assets/less/variables.less";

#hobbies {
  background-color: @panel-hobbies--bg-color;

  .dart {
    border-top: 40px solid @panel-skills--bg-color;
  }

  .hobby {
    text-align: center;

    @media screen {
      padding-bottom: 0px;

      @media (max-width: 549px) {
        padding-bottom: 20px;
      }
    }
  }

  .hobby img {
    display: block;
    margin: auto;
    margin-bottom: 5px;
    width: 100px;
  }

  #hobby_list {
    margin-bottom: 30px;
  }

}
</style>
