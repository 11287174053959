<template>
  <path ref="area" class="area" :style="style" />
</template>

<script>
import * as d3 from 'd3';

export default {
  name: 'Area',
  props: {
    layout: {
      type: Object,
      default: () => {},
    },
    seriesData: {
      type: Object,
      default: () => {},
    },
    scale: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    style() {
      return {
        fill: this.scale.color(this.seriesData.id),
        fillOpacity: 0.25,
      };
    },
  },
  watch: {
    // Changes to scale means we have to redraw the line!
    scale: {
      deep: true,
      handler: 'drawArea',
    },
    seriesData: {
      deep: true,
      handler: 'drawArea',
    },
  },
  mounted() {
    this.drawArea();
  },
  methods: {
    drawArea() {
      // Get scale
      const { scale } = this;

      // Area object
      const area = d3.area()
        .x((d) => scale.x(d.timestamp))
        .y0(scale.y(0))
        .y1((d) => scale.y(d.value));

      // DOM node for area
      const $area = d3.select(this.$refs.area);

      $area
        .datum(this.seriesData.values.filter((d) => typeof d.value !== typeof null))
        .attr('d', area);
    },
  },
};
</script>
