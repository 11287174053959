<template>
  <path ref="line" class="line" :style="style" />
</template>

<script>
import * as d3 from 'd3';

export default {
  name: 'LineD',
  props: {
    layout: {
      type: Object,
      default: () => {},
    },
    seriesData: {
      type: Object,
      default: () => {},
    },
    scale: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    style() {
      return {
        fill: 'none',
        stroke: this.scale.color(this.seriesData.id),
        strokeWidth: 2,
      };
    },
  },
  watch: {
    // Changes to scale means we have to redraw the line!
    scale: {
      deep: true,
      handler: 'drawLine',
    },
    seriesData() {
      this.drawLine();
    },
  },
  mounted() {
    this.drawLine();
  },
  methods: {
    drawLine() {
      // Get scale
      const { scale } = this;

      // Line object
      const line = d3.line()
        .x((d) => scale.x(d.timestamp))
        .y((d) => scale.y(d.value));

      // DOM node for line
      const $line = d3.select(this.$refs.line);
      $line
        .data([this.seriesData.values.filter((d) => typeof d.value !== typeof null)])
        .attr('d', line);

      const totalLength = $line._groups[0][0].getTotalLength();
      $line
        .attr('stroke-dasharray', `${totalLength} ${totalLength}`)
        .attr('stroke-dashoffset', totalLength)
        .transition()
        .duration(500)
        .ease(d3.easeLinear)
        .attr('stroke-dashoffset', 0);
    },
  },
};
</script>
