import Vue from 'vue';
import firebase from 'firebase/app';
import App from './App.vue';
import router from './router';
import store from './store';
import i18n from './i18n';

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount('#app');


// firebase
const firebaseConfig = {
  apiKey: 'AIzaSyCuaxvcoBduiQzNT76WzZ110ve2qL_KcTs',
  authDomain: 'binzram-io.firebaseapp.com',
  databaseURL: 'https://binzram-io.firebaseio.com',
  projectId: 'binzram-io',
  storageBucket: 'binzram-io.appspot.com',
  messagingSenderId: '320366488294',
  appId: '1:320366488294:web:b70ce63153e2106e',
};

firebase.initializeApp(firebaseConfig);
